import React, {lazy, Suspense, useEffect, useState} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import MainNavBar from "../components/navbar";
import MapComponent from "../components/map";
import ToggleButtons from "../components/toggle-buttons";
import FarmList from "../components/smart-farm/farm/farm-list";
import ChangePassword from "../shared/components/change-password";
import ConfirmationDialog from "../shared/components/confirmation-dialog";
import TimePicker from "../components/time-picker";
import {useSelector} from "react-redux";
import {isMobileView} from "../utils/Utils";
import {TOGGLE, VIEW} from "../utils/Enum";
import ModuleConfig from "../components/settings/module-config";
import SmartFarmLayout from "../components/smart-farm/smart-farm-layout";
import PlotLayout from "../components/smart-farm/plot/plot-layout";
import PlotLayoutSettings from "../components/settings/plot/plot-layout-settings";
import Loader from "../shared/components/loader";
import CorporateList from "../components/corporate/corporate-list";
import Buyer from "../components/buyer-profile/buyer";
import CCOperations from "../components/cc-operations/cco-layout";
import NotificationPage from "../components/notification-page";
import AllTasks from "../components/daily-update/all-tasks";
import CCR from "../components/ccr-profile/ccr-layout";
import Account from "../components/account-setting/account";

const DailyUpdateLayout = lazy(() => import('../components/daily-update/daily-update-layout'));
const CashFlowLayout = lazy(() => import('../components/cash-flow/cash-flow-layout'));
const SystemSettings = lazy(() => import('../components/system-settings/system-settings'));
const WaterResources = lazy(() => import('../components/corporate/corporate-layout'));
const BuyerDetails = lazy(() => import('../components/buyer-profile/buyer-layout'));
const AccountDetails = lazy(() => import('../components/account-setting/account-layout'));
const Xindicate = lazy(() => import('../components/xindicate/xindicate'));
const SettingsLayout = lazy(() => import('../components/settings/settings-layout'));
const MapDataLayout = lazy(() => import('../components/map-data/map-data-layout'));
const DashboardLayout = lazy(() => import('../components/dashboard/dashboard-layout'));

const MainLayout = () => {
  const [mapHeight, setMapHeight] = useState({});

  const findDeviceLocation = useSelector(state => {
    return state.sensor.findLocation;
  });

  const view = useSelector(state => {
    return state.setting.view
  });

  const toggle = useSelector(state => {
    return state.setting.toggle
  });

  const winHeight = window.innerHeight;

  useEffect(() => {
    setMapHeight((winHeight / 2) - 64);
  }, []);

  useEffect(() => {
    if (isMobileView() && view === VIEW.FARM_LIST && toggle === TOGGLE.MAP) {
      setMapHeight(winHeight - 290);
    } else {
      setMapHeight((winHeight / 2) - 64);
    }
  }, [view, toggle]);


  return (
    <div className={"h-100pe"}>
      <Suspense fallback={<Loader load={true}/>}>
        <MainNavBar/>
        <ConfirmationDialog/>
        <ToggleButtons/>
        <ChangePassword/>
      </Suspense>
      {view !== VIEW.XINDICATE_ALL &&
        <MapComponent
          mapElement={<div className={findDeviceLocation ? "location-picker" : ""} style={{height: `100%`}}/>}
          containerElement={<div className={toggle === TOGGLE.MAP ? "map-z-index" : null} style={{
            height: mapHeight,
            top: "64px",
            width: "100%",
            position: "fixed",
            transition: "0.4s"
          }}/>}
        />
      }
      <TimePicker/>
      <Suspense fallback={<Loader load={true}/>}>
        <Switch>
          <Route path="/home" component={FarmList}/>
          <Route path="/module-config" component={ModuleConfig}/>
          <Route path="/system-config" component={SystemSettings}/>
          <Route path="/alltasks" component={AllTasks}/>
          <Route path="/xindicate" component={Xindicate}/>
          <Route path="/cco" component={CCOperations}/>
          <Route path="/corporate/:corporateId/*" component={WaterResources}/>
          <Route path="/buyer/:buyerId/*" component={BuyerDetails}/>
          <Route path="/account/:accountId/*" component={AccountDetails}/>
          <Route path="/corporate" component={CorporateList}/>
          <Route path="/buyer" component={Buyer}/>
          <Route path="/account" component={Account}/>
          <Route path="/ccr" component={CCR}/>
          <Route path="/notification-page" component={NotificationPage}/>
          {/*<Route path="/cash-flow" component={FarmListCashFlow}/>*/}
          <Route path="/:farmId/cash-flow/*" component={CashFlowLayout}/>
          {/*<Route path="/daily-update" component={FarmListDailyUpdate}/>*/}
          <Route path="/:farmId/daily-update/*" component={DailyUpdateLayout}/>
          <Route path="/:farmId/map-data/*" component={MapDataLayout}/>
          <Route path="/:farmId/dashboard" component={DashboardLayout}/>
          {/*<Route path="/settings" component={FarmListSettings}/>*/}
          <Route path="/:farmId/settings/plot/:plotId/*" component={PlotLayoutSettings}/>
          <Route path="/:farmId/settings/*" component={SettingsLayout}/>
          <Route path="/:farmId/plot/:plotId/*" component={PlotLayout}/>
          <Route path="/:farmId/*" component={SmartFarmLayout}/>


          <Redirect exact from='/' to='/home'/>

        </Switch>
      </Suspense>
    </div>
  );
};

export default MainLayout;
