import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {clusterSensorsByDeviceId} from "../../../utils/Utils";
import {useDispatch, useSelector} from "react-redux";
import {getClusters} from "../../../actions/sensor";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import {toggleLoader} from "../../../shared/actions/setting";
import {toast} from "react-toastify";
import SensorItem from "./sensor-item";
import {getSelectedKit} from "../../../actions/plot";
import {useTranslation} from "react-i18next";
import {isEmpty} from "../../../shared/utils/utils";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {database} from "../../../utils/firebaseConfig";
import {onValue, ref} from "firebase/database";

const SensorList = () => {
  const {t} = useTranslation();
  const [kit, setKit] = useState({});
  const [requestPlotKitId, setRequestPlotKitId] = useState(null);
  const dispatch = useDispatch();
  const cancelToken = axios.CancelToken;
  let cancel;
  const [properties, setProperties] = useState([]);
  const [sensorInputEnabled, setSensorInputEnabled] = useState(false);

  const selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });

  const clusters = useSelector(state => {
    return state.sensor.clusters;
  });

  const selectedFarm = useSelector(state => {
    return state.farmList.selectedFarm;
  });

  useEffect(() => { 
    if (!selectedFarm) return;

    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/corporate/` + selectedFarm.corporateId + `/config`)
    .then((res) => {
      if (res.data.content > 0 && res.data.content[0]) {
        setSensorInputEnabled(res.data.content[0].SENSOR_DATA);
      }
    })
  }, [selectedFarm]);

  useEffect(() => {
    if (!selectedPlot.kitId) {
      setKit({});
      return;
    }

    if (!isEmpty(kit) && selectedPlot.kitId !== kit.id) {
      setKit({});
    }

    if (selectedPlot.kitId === requestPlotKitId) {
      return;
    }

    dispatch(toggleLoader(true));
    setRequestPlotKitId(selectedPlot.kitId);
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + selectedPlot.kitId, {
      cancelToken: new cancelToken(c => {
        cancel = c
      })
    }).then(res => {
      dispatch(getSelectedKit(res.data.content));
      setKit(res.data.content);
    }).catch(error => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      }
    ).finally(() => {
      dispatch(toggleLoader(false))
      setRequestPlotKitId(null);
    })
  }, [selectedPlot]);

  useEffect(() => {
    if (!kit) {
      return;
    }
    if (hasCluster()) { //new method

    } else { //old method
      dispatch(getClusters(clusterSensorsByDeviceId(kit, selectedPlot)));
    }
  }, [kit]);

  useEffect(() => {
    return function cleanup() {
      cancel && cancel();
    }
  }, []);


  function hasCluster() {
    if (isEmpty(selectedPlot.propertyDetails)) {
      return false;
    }

    for (let key in selectedPlot.propertyDetails) {
      let value = selectedPlot.propertyDetails[key];
      if (!value.clusterClass) {
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    if (!selectedPlot.kitId) {
      return
    }

    const query = ref(database, 'kits/' + selectedPlot.kitId + '/');
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log('FB:ref=> kits/' + selectedPlot.kitId + " data=>", data);  //dont delete this log

      if (snapshot.exists()) {
        setProperties(data);
      }
    });
  }, [selectedPlot]);

  useEffect(() => {
    if (kit.properties !== undefined) {
      const updatedKit = {...kit};
      updatedKit.properties = properties
      setKit(updatedKit)
    }
  }, [properties]);

  return (
    <Container className={"plot-container-resp"}>
      <div id={"sensorListContainer"} className={window.innerWidth > 620 ? "row" : "mo-plot-list"}>
        {clusters.map((cluster, index) => (
          <SensorItem index={index} cluster={cluster} key={index} kit={kit} sensorInputEnabled={sensorInputEnabled}/>
        ))}

        {selectedPlot.kitId && kit && kit.properties && kit.properties.length !== 0 && clusters.length === 0 && ( sensorInputEnabled && kit.model && kit.model.properties ? 
            <SensorItem index={0} key={0} kit={kit} sensorInputEnabled={sensorInputEnabled}/>
           : 
          (<div className={"empty-results"}>
            <FeatherIcon icon="info"/>
            <div className={"empty-results-text"}>Sensor(s) are not located or data not received yet, please add
              location or contact admin
            </div>
          </div>))
        }

        {selectedPlot.kitId && kit && kit.properties && kit.properties.length === 0 && ( sensorInputEnabled && kit.model && kit.model.properties ?
          <SensorItem index={0} key={0} kit={kit} sensorInputEnabled={sensorInputEnabled}/>
           : 
          <div className={"empty-results"}>
           <FeatherIcon icon="info"/>
           <div className={"empty-results-text"}>Data not received yet, contact admin</div>
         </div>
          )
        }

        {!selectedPlot.kitId && (
          <div className={"empty-results"}>
            <FeatherIcon icon="info"/>
            <div className={"empty-results-text"}>{t("emptyMsg.SENSORS")}</div>
          </div>)
        }

      </div>
    </Container>

  )
};


export default SensorList
