import axios from "axios";
import React, {useEffect, useState} from "react";
import {GoogleMap, InfoWindow, Marker, OverlayView, Polygon, withGoogleMap, withScriptjs} from "react-google-maps"
import {useParams} from "react-router-dom";
import {compose} from "recompose"
import {getRole, getUserId} from "../../../shared/utils/SharedAuthentication";
import CustomOverlayView from "../../../utils/CustomOverlayView";
import deviceMapStyle from './devicesMapStyle'

import icon from '../../images/dripper.png'
import selectedIcon from '../../images/dripper-selected.png'


const DevicesMap = compose(
  withScriptjs,
  withGoogleMap
)(props => {
  const [plotList, setPlotList] = useState([])
  const [valveList, setValveList] = useState([])
  const {farmId} = useParams();
  const [farmCoordinate, setFarmCoordinates] = useState([])
  const [selectedPlot, setSelectedPlot] = useState('');
  const [refresh, setRefresh] = useState(0);
  const [map, setMap] = useState(null);


  useEffect(() => {
    if (map && farmCoordinate.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      farmCoordinate.forEach((path) => {
        farmCoordinate.forEach((point) => {
          bounds.extend(point);
        });
      });
      map.fitBounds(bounds);
    }
  }, [map, farmCoordinate]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farmId/` + farmId + '/sectors-with-irrigation')
      .then((response) => {
        setPlotList(response.data.content)
      })

    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/valve')
      .then((response) => {
        setValveList(response.data.content)
      })

    if (refresh === 0) {
      axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId)
        .then(res => {
          setFarmCoordinates(res.data.content.coordinates);
        });
    }

  }, [refresh]);

  function getCenter(coordinates) {
    if (!coordinates) {
      return {
        lat: 6.885047,
        lng: 79.855808
      };
    }
    let lat = 0;
    let lng = 0;
    for (let i = 0; i < 3; i++) {
      lat += coordinates[i].lat;
      lng += coordinates[i].lng;
    }
    return {lat: lat / 3, lng: lng / 3};

  }


  function getCenterPoint(coordinates) {
    if (!coordinates) {
      return {
        lat: 6.885047,
        lng: 79.855808
      };
    }
    let x = 0;
    let y = 0;
    let j = coordinates.length - 1;
    let f;
    let area = 0;
    for (let i = 0; i < coordinates.length; j = i, i++) {
      const point1 = coordinates[i];
      const point2 = coordinates[j];
      f = point1.lat * point2.lng - point2.lat * point1.lng;
      x += (point1.lat + point2.lat) * f;
      y += (point1.lng + point2.lng) * f;
      area += point1.lat * point2.lng;
      area -= point1.lng * point2.lat;
    }
    area /= 2;
    f = area * 6;
    return {
      lat: x / f,
      lng: y / f
    };
  }

  function renderValveBasedOnPlotOrFarmSettingsType(plot) {
    return ((!props.isPlotSettings && !(props.plotId == plot.referenceId)) || props.plotId == plot.referenceId)
  }

  function getIrrigationTitle(irrigation) {
    if (getRole() === 1) {
      return (irrigation.irrigationName ? irrigation.irrigationName : "Irrigation") + " [" + irrigation.valveCode + "]";
    }

    return irrigation.irrigationName ? irrigation.irrigationName : "Irrigation";
  }

  function getMarkerCordinate(valveCode) {
    let coordinates = {}
    valveList.map((valve) => {
      if (valve.code == valveCode && valve.coordinate) {
        coordinates = valve.coordinate
      }
    })

    return coordinates;
  }

  function onClickMarker(plot, irrigation) {
    setRefresh(refresh + 1)

    setSelectedPlot(plot.referenceId)
    props.setIrrigationId(irrigation.id)
    props.setReference(plot.referenceId)
    props.setIrrigation(irrigation)
  }

  return (
    plotList.length > 0 && <GoogleMap
      ref={(map) => setMap(map)}
      defaultZoom={10}

      defaultOptions={{
        styles: deviceMapStyle,
        mapTypeControl: false,
        fullscreenControl: false,
        scaleControl: false,
        rotateControl: false,
        panControl: false,
        zoomControl: false,
        streetViewControl: false
      }}


      defaultCenter={farmCoordinate ? farmCoordinate.length > 0 ? getCenterPoint(farmCoordinate) : {
        lat: -34.397,
        lng: 150.644
      } : {lat: -34.397, lng: 150.644}}
    >

      {farmCoordinate.length > 0 && <Marker
        opacity={1}

      >


        <Polygon

          // onClick={(e) => farmClick(farm, e)}
          path={farmCoordinate.length > 0 ? farmCoordinate : []}
          // key={index}
          editable={false}
          options={{
            fillColor: true ? "#f7fdfb" : "#A3A3A37A",
            fillOpacity: true ? 1 : 0.5,
            strokeColor: true ? "#22C6877B" : "#A3A3A37A",
            strokeOpacity: plotList.length === 0 ? 1 : 0.5,
            strokeWeight: 1,
          }}
        />
      </Marker>}
      {plotList.length > 0 && (plotList.filter(plot => plot.coordinates).map((plot, index) =>
          renderValveBasedOnPlotOrFarmSettingsType(plot) && <div key={'plot' + index}>
            <CustomOverlayView
              position={getCenter(plot.coordinates)}
              /*
               * An alternative to specifying position is specifying bounds.
               * bounds can either be an instance of google.maps.LatLngBounds
               * or an object in the following format:
               * bounds={{
               *    ne: { lat: 62.400471, lng: -150.005608 },
               *    sw: { lat: 62.281819, lng: -150.287132 }
               * }}
               */
              /*
               * 1. Specify the pane the OverlayView will be rendered to. For
               *    mouse interactivity, use `OverlayView.OVERLAY_MOUSE_TARGET`.
               *    Defaults to `OverlayView.OVERLAY_LAYER`.
               */
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              /*
               * 2. Tweak the OverlayView's pixel position. In this case, we're
               *    centering the content.
               */
              // getPixelPositionOffset={getPixelPositionOffset}
              /*
               * 3. Create OverlayView content using standard React components.
               */
            >
              <div className={"plot-label"}>
                {plot.referenceId == farmId ? "" : plot.referenceName}
              </div>
            </CustomOverlayView>
            <Polygon
              path={plot.coordinates}
              key={index}
              editable={false}
              options={{
                geodesic: true,
                fillOpacity: 0.5,
                fillColor: props.plotId == plot.referenceId || selectedPlot == plot.referenceId ? "#7db6fb" : "#eaf5fb",
                strokeColor: props.plotId == plot.referenceId || selectedPlot == plot.referenceId ? "#6eadfb" : "#ddedfa",
                strokeOpacity: 1,
                strokeWeight: 1,
                zIndex: 1000,
              }}
              // onClick={(e) => goToSensor(plot, e)}
            />
            {plot.irrigations && plot.irrigations.length > 0 && plot.irrigations.map((irr, index) =>

              (
                <div key={'marker' + index} style={{width: 30}}>
                  <Marker

                    key={index}
                    onClick={() => {
                      onClickMarker(plot, irr)
                    }}
                    shape='circle'
                    defaultShape={'circle'}
                    position={{
                      lat: irr.type === "PLOT" ? getMarkerCordinate(irr.valveCode).lat ? getMarkerCordinate(irr.valveCode).lat : ((plot.coordinates[index].lat) + ((plot.coordinates[0].lat) + (plot.coordinates[1].lat) + (plot.coordinates[2].lat)) / 3) / 2 : plot.coordinates[index].lat,
                      lng: irr.type === "PLOT" ? getMarkerCordinate(irr.valveCode).lng ? getMarkerCordinate(irr.valveCode).lng : ((plot.coordinates[index].lng) + ((plot.coordinates[0].lng) + (plot.coordinates[1].lng) + (plot.coordinates[2].lng)) / 3) / 2 : plot.coordinates[index].lng
                    }}


                    icon={{
                      url: props.irrigation ? props.irrigation.id === irr.id ? selectedIcon : icon : icon,
                      anchor: new window.google.maps.Point(0.5, 0.5),
                      scaledSize: new window.google.maps.Size(25, 25)
                    }}
                  >
                    <InfoWindow
                      style={{padding: -10}}
                      position={plot.coordinates[0]}
                      options={{width: '15px'}}>
                      <div style={{fontSize: "14px", marginRight: 0}} className="farm-label-box sa-cursor"
                           onClick={() => onClickMarker(plot, irr)}
                      >
                        {getIrrigationTitle(irr)}
                      </div>
                    </InfoWindow>

                  </Marker>
                </div>
              ))}


          </div>
      ))
      }

    </GoogleMap>
  )
});


export default DevicesMap
