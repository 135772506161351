import React, {useState} from "react";
import user from "../../../images/BasicDetails.svg";
import ToggleLayout from "../../toggle-layout";
import FeatherIcon from "feather-icons-react";
import {useTranslation} from "react-i18next";
import FarmImages from "../../farm/farm-images";


export default function CropAgronomyView(props) {
  const [visibleToggleIndex, setVisibleToggleIndex] = useState(1);
  const [steps, setSteps] = useState(0);
  const {t, i18n} = useTranslation();
  const [selectedStage, setSelectedStage] = useState(props.crop.stages ? props.crop.stages[0] : null);

  return (
    <ToggleLayout image={user} title={t("title.CROP_AGRONOMY_DETAILS")} dropDown={false}
                  visibleToggleIndex={visibleToggleIndex}
                  toggleIndex={1}
                  onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
      {/* <div className="table-actions float-right pdf-hide" data-toggle="tooltip" title="Download PDf">
              <FeatherIcon className="table-action" icon={"download"} onClick={() => props.onPrint()}/>
          </div> */}
      <div className={"row"}>
        {/*<GenericPdfDownloader*/}
        {/*  downloadFileName="CustomPdf"*/}
        {/*  rootElementId="avdfsd"*/}
        {/*/>*/}

        <div className={"col-12"}>
          <div className={"row justify-content-between gap-mb-24"}>
            <div className={"col-md-4 w-150"}>
              <div className={"view-label"}>
                {t("title.CROP_NAME")}
              </div>
              <div className={"view-value"}>
                {props.crop.cropName ? props.crop.cropName : "NA"}
              </div>
            </div>
            <div className={"col-md-4 w-150"}>
              <div className={"view-label"}>
                {t("title.CROP_VARIETY")}
              </div>
              <div className={"view-value"}>
                {props.crop.category ? props.crop.category : "NA"}
              </div>
            </div>
            <div className={"col-md-4 w-150"}>
            <div className={"view-label"}>
                {t("title.CROP_IMAGE")}
              </div>
              {props.crop.image &&
                <div className={''}>
                  <FarmImages imgs={[props.crop.image]} width={"106px"}/>
                </div>
              }
              {!props.crop.image &&
                <div className={"view-value"}>
                  NA
                </div>
              }
            </div>
            {/* <div className={"col-md-auto"}>
                          <div className={"view-label"}>
                              Pests
                          </div>
                          <div className={"view-value"}>
                              {props.crop.pests ? props.crop.pests : "NA"}
                          </div>
                      </div> */}
            <div className={"col-md-12 mt-5"}>
              <div className={"view-label"}>
                {t("title.PESTS")}
              </div>
              <div className={"view-value"}>
                <div className="sa-table-container mt-0">
                  <table className="table table-borderless sa-table-width">
                    <thead>
                    <tr>
                      <th className={"sa-table-head-sticky"}>{t("title.NAME")}</th>
                      <th className={"sa-table-head-sticky"}>{t("title.PEST_CATEGORY")}</th>
                      <th className={"sa-table-head-sticky"}>{t("title.PRACTICES")}</th>
                      <th className={"sa-table-head-sticky"}>{t("title.PEST_IMAGE")}</th>
                      <th className={"sa-table-head-sticky"}>{t("title.SYMPTOMS")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.crop.pests && props.crop.pests.map((crop, index) =>
                      <tr key={index} style={{fontWeight:"normal"}}>
                        <td className={'sa-table-data'}>{crop.name}</td>
                        <td className={'sa-table-data'}>{crop.pestCategory ? crop.pestCategory.replace("_", " ") : "N/A"}</td>
                        <td className={'sa-table-data'}>{crop.practices}</td>
                        <td className={'sa-table-data'}>{crop.images ? <FarmImages imgs={crop.images} width={"106px"}/> : "NA"}</td>
                        <td className={'sa-table-data'}>{crop.symptoms}</td>

                      </tr>)}
                    </tbody>
                  </table>
                  {!props.crop.pests && (
                    <div className={"empty-results"}>
                      <FeatherIcon icon="info"/>
                      <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_PESTS")}</div>
                    </div>)
                  }
                </div>
              </div>
            </div>


          </div>


        </div>
      </div>
      <div className={"view-label mt-5"}>
        {t("title.STAGES")}
      </div>
      <div className={"crop-agronomy-view-box"}>
        <div className={"d-flex justify-content-center align-items-center connector-container print-hide mb-5 mt-3"}>
          {props.crop.stages && props.crop.stages.map((item, index) => (
            <div>
              <hr hidden={index === 0} className="connector-hr-line print-hide"/>
              <div className={"d-flex flex-column align-items-center mb_-68 "} onClick={() => {
                setSteps(index);
                setSelectedStage(item);
              }}>

                <span className={"connector-number print-hide " + (steps === index ? "connector-number-active" : " ")}>
                  {parseInt(index + 1)}
                </span>
                <div
                  className={"connector-txt height-20 print-hide " + (steps === index ? "connector-txt-active" : " ")}>
                  {item.name}
                </div>
                {/*{steps === index &&*/}
                {/*<div className="crop-arrow print-hide" >*/}
                {/*  <img src={Arrow} alt="arrow"/>*/}
                {/*  </div>*/}

                {/*}*/}
              </div>
            </div>
          ))}


          {(!props.crop.stages || props.crop.stages.length === 0) && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_STAGES")}</div>
            </div>
          )}
        </div>


        {/* <div className={"crop-view-head"}>Flowering Stage Qualities</div>
          <div className="row p-1 mt-4 crop-agronomy-sensor">
                <div className="sensor-details crop-w-15per">
                  {getIcon("T")}
                  <div className="sensor-data sensor-data-p">
                    <div className="sensor-value limit-sensorValue-sa w-73">dsf</div>
                    <div className="sensor-Name limit-sensorName-sa w-73">sdfw</div>
                  </div>
                </div>
                <div className="sensor-details crop-w-15per">
                  {getIcon("WD")}
                  <div className="sensor-data sensor-data-p">
                    <div className="sensor-value limit-sensorValue-sa w-73">dsf</div>
                    <div className="sensor-Name limit-sensorName-sa w-73">sdfw</div>
                  </div>
                </div>
                <div className="sensor-details crop-w-15per">
                  {getIcon("M")}
                  <div className="sensor-data sensor-data-p">
                    <div className="sensor-value limit-sensorValue-sa w-73">dsf</div>
                    <div className="sensor-Name limit-sensorName-sa w-73">sdfw</div>
                  </div>
                </div>
                <div className="sensor-details crop-w-15per">
                  {getIcon("IRO")}
                  <div className="sensor-data sensor-data-p">
                    <div className="sensor-value limit-sensorValue-sa w-73">dsf</div>
                    <div className="sensor-Name limit-sensorName-sa w-73">sdfw</div>
                  </div>
                </div>
                <div className="sensor-details crop-w-15per">
                  {getIcon("CN")}
                  <div className="sensor-data sensor-data-p">
                    <div className="sensor-value limit-sensorValue-sa w-73">dsf</div>
                    <div className="sensor-Name limit-sensorName-sa w-73">sdfw</div>
                  </div>
                </div>
                <div className="sensor-details crop-w-15per">
                  {getIcon("EV")}
                  <div className="sensor-data sensor-data-p">
                    <div className="sensor-value limit-sensorValue-sa w-73">dsf</div>
                    <div className="sensor-Name limit-sensorName-sa w-73">sdfw</div>
                  </div>
                </div>
          </div> */}
        <div className={'stage-display'}>
          {props.crop.stages && props.crop.stages.map((item, index) => (
            <div>
                <span className={"connector-txt "}>
                <span className={'print-satge-name m-l-6 m-r-8'}>{parseInt(index + 1)}</span>
                <span className={'print-satge-name'}>{"." + item.name}</span>
                </span>
              <div hidden={!item.fertiliser}>
                <div className={"crop-view-head mt-3"}>{t("title.FERTILISER")}</div>
                <div className={"flex mt-3 m-l-8 mb-3"}>
                  <div className={"green-dot mt-2"}></div>
                  <div className={"agronomy-practices-text m-l-5 mt-0"}>{item.fertiliser}</div>
                </div>
              </div>
              <div hidden={!item.irrigationRequirement}>
                <div className={"crop-view-head mt-3"}>{t("title.IRRIGATION_REQUIREMENT")}</div>
                <div className={"flex mt-3 m-l-8 mb-3"}>
                  <div className={"green-dot mt-2"}></div>
                  <div className={"agronomy-practices-text m-l-5 mt-0"}>{item.irrigationRequirement}</div>
                </div>
              </div>
              <div hidden={!item.practices}>
                <div className={"crop-view-head mt-3"}>{t("title.SYMPTOMS")}{t("title.PRACTICES")}</div>
                <div className={"flex mt-3 m-l-8 mb-3"}>
                  <div className={"green-dot mt-2"}></div>
                  <div className={"agronomy-practices-text m-l-5 mt-0"}>{item.practices}</div>
                </div>
              </div>
            </div>
          ))}


        </div>

        {selectedStage &&
          <div className={'stages-container print-hide'}>
            {selectedStage.fertiliser &&
              <span>
          <div className={"crop-view-head mt-3"}>{t("title.FERTILISER")}</div>
            <div className={"flex mt-3 m-l-8 mb-3"}>
                <div className={"green-dot mt-2"}></div>
                <div className={"agronomy-practices-text m-l-5 mt-0"}>{selectedStage.fertiliser}</div>
            </div>
  </span>
            }
            {selectedStage.irrigationRequirement &&
              <span>
                <div hidden={!selectedStage.irrigationRequirement}
                     className={"crop-view-head mt-3"}>{t("title.IRRIGATION_REQUIREMENT")}</div>
            <div className={"flex mt-3 m-l-8 mb-3"}>
                <div className={"green-dot mt-2"}></div>
                <div className={"agronomy-practices-text m-l-5 mt-0"}>{selectedStage.irrigationRequirement}</div>
            </div>
  </span>
            }
            {selectedStage.practices &&
              <span>
                <div hidden={!selectedStage.practices} className={"crop-view-head mt-3"}>{t("title.PRACTICES")}</div>
            <div className={"flex mt-3 m-l-8 mb-3"}>
                <div className={"green-dot mt-2"}></div>
                <div className={"agronomy-practices-text m-l-5 mt-0"}>{selectedStage.practices}</div>
            </div>
  </span>
            }

            <span>
                <div className={"crop-view-head mt-3"}>{t("title.DURATION")}</div>
            <div className={"flex mt-3 m-l-8 mb-3"}>
                <div className={"green-dot mt-2"}></div>
                <div className={"agronomy-practices-text m-l-5 mt-0"}>{selectedStage.duration}</div>
            </div>
  </span>

            {selectedStage.generalAdvices &&
              <span>
                <div hidden={!selectedStage.generalAdvices}
                     className={"crop-view-head mt-3"}>{t("title.GENERAL_ADVICES")}</div>
            <div className={"flex mt-3 m-l-8 mb-3"}>
                <div className={"green-dot mt-2"}></div>
                <div className={"agronomy-practices-text m-l-5 mt-0"}>{selectedStage.generalAdvices}</div>
            </div>
  </span>}
            {selectedStage.pestAndDiseases && selectedStage.pestAndDiseases.map((diseases, num) =>
              (<span key={num + "num"}>
                <div hidden={!selectedStage.pestAndDiseases}
                     className={"crop-view-head mt-3"}>{t("title.PEST_AND_DISEASES")}</div>
                {Object.keys(selectedStage.pestAndDiseases[num]).map((itemKey, i) => (
                  <div className={"flex mt-3 m-l-8 mb-3"} key={i + "type"}>
                    <div className={"green-dot mt-2"}></div>
                    <div className={"agronomy-practices-text m-l-5 mt-0"}>{itemKey + " - "}</div>
                    <div
                      className={"agronomy-practices-text m-l-5 mt-0"}>{selectedStage.pestAndDiseases[num][itemKey]}</div>
                  </div>))}
  </span>))}

            {selectedStage.alertThreshold &&
              <span>
                <div hidden={!selectedStage.alertThreshold}
                     className={"crop-view-head mt-3"}>{t("title.ALERT_THRESHOLD")}</div>
            <div className={"flex mt-3 m-l-8 mb-3 flex-column"}>
                <div className={"d-flex mb-2"}>
                <div className={"green-dot mt-2"}></div>
                <div className={"agronomy-practices-text m-l-5 mt-0"}>{t("title.TEMPERATURE") + " - "}</div>
                <div className={"agronomy-practices-text m-l-5 mt-0"}>{selectedStage.alertThreshold["T"]}</div>
                </div><div className={"d-flex mb-2"}>
                <div className={"green-dot mt-2"}></div>
                <div className={"agronomy-practices-text m-l-5 mt-0"}>{t("title.HUMIDITY") + " - "}</div>
                <div className={"agronomy-practices-text m-l-5 mt-0"}>{selectedStage.alertThreshold["H"]}</div>
            </div>
            </div>
  </span>}
            {selectedStage.irigationThreshold &&
              <span>
                <div hidden={!selectedStage.irigationThreshold}
                     className={"crop-view-head mt-3"}>{t("title.IRIGATION_THRESHOLD")}</div>
            <div className={"flex mt-3 m-l-8 mb-3 flex-column"}>
                <div className={"d-flex mb-2"}>
                <div className={"green-dot mt-2"}></div>
                <div className={"agronomy-practices-text m-l-5 mt-0"}>{t("title.TEMPERATURE") + " - "}</div>
                <div className={"agronomy-practices-text m-l-5 mt-0"}>{selectedStage.irigationThreshold["T"]}</div>
                </div>
                <div className={"d-flex mb-2"}>
                <div className={"green-dot mt-2"}></div>
                <div className={"agronomy-practices-text m-l-5 mt-0"}>{t("title.HUMIDITY") + " - "}</div>
                <div className={"agronomy-practices-text m-l-5 mt-0"}>{selectedStage.irigationThreshold["H"]}</div>
            </div>
            </div>
  </span>}
            {selectedStage.advices && selectedStage.advices.map((dataAdvices, index) => (
              <span>
                <div hidden={!selectedStage.advices} className={"crop-view-head mt-3"}>{t("title.ADVICES")}</div>
            <div className={"flex mt-3 m-l-8 mb-3 "}>
                {/*{Object.keys(subjects).map((item, i) => (*/}
              {/*    <li className="travelcompany-input" key={i}>*/}
              {/*        <span className="input-label">key: {i} Name: {subjects[i]}</span>*/}
              {/*    </li>*/}
              {/*))}*/}
              {/*Every x days  for*/}
              <div
                className={"agronomy-practices-text m-l-5 mt-0"}>{`Every ${selectedStage.advices[index]["interval"]} ${selectedStage.advices[index]["granularity"]} for   ${selectedStage.advices[index]["soilType"]} and climate  ${selectedStage.advices[index]["climate"]}`}</div>
            </div>
                {dataAdvices.advices.map((subAdvice, subAdviceNum) => (
                  <div key={subAdviceNum + "subAdviceNum"} className={"d-flex m-l-8"}>
                    <div className={"green-dot mt-2 d-flex "}></div>
                    <div className={"agronomy-practices-text m-l-5 mt-0 mb-5"}>{"Advice - " + subAdvice.advice}</div>
                  </div>))}
  </span>))}
          </div>
        }
      </div>

    </ToggleLayout>
  )
}
